import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { config } from 'config';
import { differenceInMinutes } from 'date-fns';
import { snapActionsApi } from 'Lib/Api/snap-actions-api';
import { resetToken } from 'Lib/HelperFunction/resetToken';
import { setAuthToken } from 'Lib/HelperFunction/setAuthToken';
import routes from 'Lib/Routes/Routes';

export default function RefreshToken() {
  const navigate = useNavigate();
  useEffect(() => {
    setInterval(async () => {
      const loginTime = localStorage.getItem(config.storage.loginTime);
      const refreshToken = localStorage.getItem(config.storage.refreshToken);
      if (refreshToken && loginTime) {
        const logTime = JSON.parse(loginTime);
        if (differenceInMinutes(new Date(), new Date(logTime)) > 4) {
          try {
            const { data } = await snapActionsApi.refreshToken(refreshToken);
            setAuthToken(data.access, data.refresh);
          } catch (refreshTokenError: any) {
            if (refreshTokenError.response && refreshTokenError.response.status === 401) {
              resetToken();
              navigate(routes.login.path, { replace: true });
            }
          }
        }
      }
    }, 1000 * 60);
  }, [navigate]);

  return <></>;
}
